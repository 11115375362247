import React from 'react';
import * as styles from './CaseStudiesHero.module.scss';
import {BlockTitle} from "../BlockTitle";
import {MarkdownContent} from "../MarkdownContent";
import classNames from 'classnames'
import {Typography} from "../Typography";

export function CaseStudiesHero({title, content, className, ...otherProps}) {
    return <section className={classNames(styles.root, className)} {...otherProps}>
        <div className={styles.content}>
        <BlockTitle>{title}</BlockTitle>
        <MarkdownContent content={content} component={Typography} />
        </div>
        <div className={styles.image} />
    </section>
}
