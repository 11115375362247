import React from "react"
import {Layout} from "../components/Layout";
import {PageMainColumnWrapper} from "../components/PageMainColumnWrapper";
import {ContactPersonBlock} from "../components/ContactPersonBlock";
import {graphql} from "gatsby";
import {MarkdownContent} from "../components/MarkdownContent";
import {rwdImages} from "../functions/rwdImages";
import {BackgroundLine} from "../components/BackgroundLine";
import {injectIntl} from 'gatsby-plugin-react-intl';
import {CaseStudiesBlock} from "../components/CaseStudiesBlock";
import {CaseStudiesHero} from "../components/CaseStudiesHero";
import {createSeoData} from "../functions/createSeoData";
import {getImage, withArtDirection} from "gatsby-plugin-image";

export const query = graphql`
    query CaseStudies($locale: String!) {
        caseStudies: allContentfulCaseStudy(filter: {node_locale: {eq: $locale}}) {
            edges {
                node {
                    title
                    color
                    slug
                    intro {
                        childMarkdownRemark {
                            html
                        }
                    }
                }
            }
        }
        page: contentfulCaseStudiesPage(node_locale: {eq: $locale}) {
            title
            content {
                childMarkdownRemark {
                    html
                }
            }
            contactPersonTitle
            contactPersonContent {
                childMarkdownRemark {
                    html
                }
            }
            contactPerson {
                ...ContactPersonBlock
            }
            seo {
                ...Seo
            }
        }
    }
`


function renderContactPerson(contactPerson, title, description) {
    if (!contactPerson) {
        return;
    }
    return (
        <ContactPersonBlock
            title={title}
            image={contactPerson.bigImage.localFile}
            mobileImage={contactPerson.mobileBigImage.localFile}
            person={{
                phone: contactPerson.phone,
                email: contactPerson.email,
                name: contactPerson.name,
                position: contactPerson.position
            }}
        >
            <MarkdownContent content={description}/>
        </ContactPersonBlock>
    );
}

function CaseStudies({data, intl, pageContext}) {
    return <Layout services={pageContext.services}
                   oldServices={pageContext.oldServices}
                   linkableSubServices={pageContext.linkableSubServices}
                   title={intl.formatMessage({id: 'menu.caseStudy'})}
                   jobsCount={pageContext.jobsCount}
                   seo={createSeoData(data.page.seo)}
                   companyData={pageContext.companyData}
                   background={<>
                       <BackgroundLine mobileOn={false}/>
                   </>}
                   lang={pageContext.lang}>
        <PageMainColumnWrapper>
            <CaseStudiesHero title={data.page.title}
                             content={data.page.content}
            />
            <CaseStudiesBlock
                caseStudies={data.caseStudies.edges.map(x => {
                    return {
                        title: x.node.title,
                        color: x.node.color,
                        description: x.node.intro,
                        slug: x.node.slug
                    };
                })}
            />
        </PageMainColumnWrapper>

        {renderContactPerson(
            data.page.contactPerson,
            data.page.contactPersonTitle,
            data.page.contactPersonContent
        )}
    </Layout>
}

export default injectIntl(CaseStudies);
