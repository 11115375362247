import React from 'react';
import * as styles from './CaseStudyMediaObject.module.scss';
import classNames from 'classnames';
import {LangLink} from "../LangLink";

export function CaseStudyMediaObject({title, children, slug, image, color, className, ...otherProps}) {
    return <article className={classNames(className, styles.root)} {...otherProps} data-color={color}>
        <LangLink className={styles.link} to={`/case-study/${slug}`}>
            <div className={styles.contentWrapper}>
                <h1 className={styles.title}>{title}</h1>
                <div className={styles.content}>{children}</div>
            </div>
            {image ? <img src={image} className={styles.image} alt=""/> : undefined}
        </LangLink>
    </article>

}
