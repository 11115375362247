import React from 'react';
import classNames from 'classnames';
import * as styles from './CaseStudiesBlock.module.scss';
import {BlockTitle} from "../BlockTitle";
import {Typography} from "../Typography";
import {CaseStudiesGrid} from "../CaseStudiesGrid";
import {CaseStudyMediaObject} from "../CaseStudyMediaObject";
import {MarkdownContent} from "../MarkdownContent";

export function CaseStudiesBlock({title, description, caseStudies, categories, className, ...otherProps}) {
    return <section className={classNames(className, styles.root)} {...otherProps}>
        {title && <BlockTitle className={styles.title}>{title}</BlockTitle>}
        {description && <Typography className={styles.description}>{description}</Typography>}
        {/*<InlineSingleOption defaultValue="all" options={categories} className={styles.filter} />*/}
        <CaseStudiesGrid className={styles.grid}>
            {caseStudies.map((x, i) => {
                const {description, ...data} = x;
                return <CaseStudyMediaObject {...data} key={i}>
                    {'childMarkdownRemark' in description ? <MarkdownContent content={description}/> : description}
                </CaseStudyMediaObject>
            })}
        </CaseStudiesGrid>
    </section>
}
